// ./src/stores/index.ts
import { createContext, useContext } from "react";
import { STORE_ROUTER, RouterStore, history } from "./router";
import { AuthStore, STORE_AUTH } from "./auth";
import { GameStore, STORE_GAME } from "./game";
import { AirdropStore, STORE_AIRDROP } from "./airdrop";
import { TwitterStore, STORE_TWITTER } from "./twitter";
import { ReferralStore, STORE_REFERRAL } from "./referral";
import { MarketplaceStore, STORE_MARKETPLACE } from "./marketplace";
function createStores() {
  return {
    [STORE_ROUTER]: new RouterStore(),
    [STORE_AUTH]: new AuthStore(),
    [STORE_GAME]: new GameStore(),
    [STORE_AIRDROP]: new AirdropStore(),
    [STORE_TWITTER]: new TwitterStore(),
    [STORE_REFERRAL]: new ReferralStore(),
    [STORE_MARKETPLACE]: new MarketplaceStore(),
  };
}

export const stores = createStores();

export const StoresContext = createContext(stores);

const useStores = () => useContext(StoresContext);

export function useRouterStore() {
  const { routerStore } = useStores();
  return routerStore;
}

export function useAuthStore() {
  const { authStore } = useStores();
  return authStore;
}

export function useGameStore() {
  const { gameStore } = useStores();
  return gameStore;
}

export function useAirdropStore() {
  const { airdropStore } = useStores();
  return airdropStore;
}

export function useTwitterStore() {
  const { twitterStore } = useStores();
  return twitterStore;
}

export function useReferralStore() {
  const { referralStore } = useStores();
  return referralStore;
}

export function useMarketplaceStore() {
  const { marketplaceStore } = useStores();
  return marketplaceStore;
}

export { history };

export const generalError = { success: false, error: "general_error" };
