import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { configureChains, createConfig } from "wagmi";
import { mainnet, goerli, polygon, polygonMumbai } from "wagmi/chains";
require("dotenv").config();

export const API_URL = process.env.REACT_APP_API_URL || "http://localhost:9003";
export const NETWORK = process.env.REACT_APP_NETWORK || "testnet";

// Web3 Modal
export const projectId = "bbdad8334bf7190ebdac1c35573a98ed";
const chains = [mainnet, goerli, polygon, polygonMumbai];
const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);

// Wagmi client
export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});

// Web3Modal Ethereum Client
export const ethereumClient = new EthereumClient(wagmiConfig, chains);

// Referral Link
export const referralLink =
  process.env.REACT_APP_REFERRAL_LINK || "http://localhost:3000/airdrop";

export const CONTRACTS = {
  "matrix-weapon": "0x18c3927efa4640afb0521cc348a02593b69226d2",
};
