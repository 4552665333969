import axios from "axios";
import * as Configs from "../config";
import { errorHandling, refreshNewToken, setHeader } from "./api";
import { flow, makeAutoObservable, observable } from "mobx";

export class AuthStore {
  user = observable.box(null);

  constructor() {
    makeAutoObservable(this);
  }

  async access(payload: any) {
    const url = `${Configs.API_URL}/v1/auth/access`;
    try {
      const result: any = await axios.post(url, payload);
      if (!result || !result.data?.success) return false;
      const jwt = result.data?.data?.jwt;
      sessionStorage.setItem("geda-token", jwt);
      return result["data"]["success"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async claimPrize(payload: any) {
    const url = `${Configs.API_URL}/v1/auth/my-bag/claim`;
    try {
      const result: any = await axios.put(url, payload, setHeader());
      if (!result || !result.data?.success) return false;
      refreshNewToken(result);
      return result?.data?.success;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getMyBag() {
    const url = `${Configs.API_URL}/v1/auth/my-bag`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      refreshNewToken(result);
      return result?.data?.data;
    } catch (e) {
      return errorHandling(e);
    }
  }

  getProfile = flow(function* (this: AuthStore) {
    const url = `${Configs.API_URL}/v1/auth/profile`;
    try {
      const result: any = yield axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      refreshNewToken(result);
      this.user.set(result?.data?.data);
      return result?.data?.data;
    } catch (e) {
      return errorHandling(e);
    }
  });

  getDashboardProfile = flow(function* (this: AuthStore) {
    const url = `${Configs.API_URL}/v1/auth/dashboard`;
    try {
      const result: any = yield axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      refreshNewToken(result);
      return result?.data?.data;
    } catch (e) {
      return errorHandling(e);
    }
  });

  logout(this: AuthStore) {
    sessionStorage.removeItem("geda-token");
    this.user.set(null);
    return;
  }
}

export const STORE_AUTH = "authStore";
